




























































import { ref, computed } from '@vue/composition-api';
import { ActionTypes } from '@/store/modules/tools/actions';
import store, { useUserActions } from '@/store';

export default {
  props: {
    isDisplayPublicDialog: {
      type: Boolean,
      default: false
    },
    programDoc: {
      type: Object,
      default: () => {}
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    }
  },
  setup(props: any) {
    const { updateProgramData } = useUserActions(['updateProgramData']);

    const programDocValue = computed(() => {
      return props?.programDoc;
    });
    const isDisplayPublicDialogValue = computed(() => {
      return props?.isDisplayPublicDialog;
    });
    console.log(props?.programDoc);

    const publicUrl = ref(programDocValue?.value?.data?.public_url);
    const dialog = ref(false);
    const isSwitchOn = ref(props?.programDoc?.data?.isPublic || false);
    const isDisplayUrl = ref(props?.programDoc?.data?.isPublic || false);
    const isLoading = ref(false);

    const getPublicUrl = () => {
      const shareableUrl = programDocValue.value.data.sharable_url;
      if (shareableUrl) {
        return shareableUrl.replace('/program/', '/public-program/');
      }
      return '';
    };

    const copy = str => {
      navigator.clipboard.writeText(str);
      store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
        message: 'URL copied to clipboard',
        type: 'success',
        isShowSnackbar: true
      });
    };

    const handleSwitchChange = async (newValue: boolean) => {
      isLoading.value = true;
      programDocValue.value.data.isPublic = newValue;
      publicUrl.value = getPublicUrl();
      programDocValue.value.data.public_url = publicUrl.value;
      await updateProgramData({
        program_id: programDocValue?.value?.data?._id,
        data: programDocValue?.value?.data
      });
      await props?.fetchProgram();
      setTimeout(() => {
        isLoading.value = false;
        if (newValue) {
          isDisplayUrl.value = true;
        } else {
          isDisplayUrl.value = false;
        }
      }, 2000);
    };

    return {
      publicUrl,
      dialog,
      isSwitchOn,
      handleSwitchChange,
      copy,
      isLoading,
      isDisplayUrl,
      isDisplayPublicDialogValue
    };
  }
};
